// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.card,
.default-card {
	border-radius: 15px;
	.card-subtitle {
		// display: none;
		font-size: $h6-font-size;
		font-family: $font-family-primary;
		font-weight: 600;
		color: $green-dark;
	}
	.card-title {
		font-size: $h5-font-size;
		color: $green-dark !important;
		margin-bottom: 0.5rem;
	}
	.card-body {
		padding-bottom: 1.5rem;
		padding-top: 2rem;
		z-index: 1;
		position: relative;
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 4rem;
			width: 100%;
			pointer-events: none;
			background-color: $primary;
			background: url("/images/Repeat Grid 9.svg");
			background-size: contain;
			background-repeat: repeat-x;
			background-position: center;
			z-index: 990;
			transform: translateY(-50%);
		}
	}
	.card-image {
		position: relative;
	}
	.card-image-caption {
		bottom: unset !important;
		background: rgba($primary, 0.3);
	}
	&:hover {
		box-shadow: $shadow;
	}
}

// search-and-book
.search-book .results {
	.list-item-image {
		position: relative;
		border-radius: 15px 15px 0 0;
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			height: 4rem;
			width: 100%;
			pointer-events: none;
			background-color: $primary;
			background: url("/images/Repeat Grid 9.svg");
			background-size: contain;
			background-repeat: repeat-x;
			background-position: center;
			z-index: 990;
			transform: translateY(50%);
		}
		img,
		.list-item-overlay {
			border-radius: 15px 15px 0 0;
		}
	}
	&.results-list {
		.list-item-image {
			border-radius: 15px 0 0 15px !important;

			&::after {
				display: none;
			}

			img,
			.list-item-overlay {
				border-radius: 15px 0 0 15px !important;
			}
		}
	}
}

// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
&.home .lead-section {
	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}
}
.lead-section {
	margin: 12vh 0 3vh 0;

	.intro .title h5 {
		font-size: 20px !important;
		font-family: $font-family-primary !important;
		font-weight: 700;
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
	.container.usps {
		padding: 3vh 0;
		.container-holder {
			max-width: 950px;
			margin: 0 auto;
			text-align: center;
		}
		ul {
			display: flex;
			justify-content: center;
			gap: 1rem;
			li {
				font-weight: 700;
				font-size: 17px;
				display: flex;
				align-items: center;
				gap: 0.25rem;
				&::before {
					font-size: 20px;
					margin: 0;
					position: relative;
				}
			}
		}
		@include media-breakpoint-down(md) {
			.container-holder {
				max-width: 100%;
			}
			ul {
				flex-flow: column;
				justify-content: center;
				align-items: center;
				li {
					text-align: center;
				}
			}
		}
	}
}

// accommodation section
.accommodation-section {
	margin: 6vh 0 12vh 0;
	.container.first,
	.container.second {
		margin: 6vh auto;
		.text {
			background: $white;
			flex-basis: 55%;
			max-width: 55%;
			border-radius: 15px;
		}
		.text {
			padding: 3vh 10vw 3vh 3vw !important;
			display: flex;
			flex-flow: column;
			justify-content: center;
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 6vh;
				transform: translateY(50%);
				background: url("/images/Repeat Grid 14.svg");
				background-size: contain;
				background-repeat: repeat-x;
			}
			.title * {
				color: $green-dark;
			}
		}
		.image-slide {
			max-width: 45%;
			flex-basis: 45%;
			transform: translate(-5vw, 6vh);
			box-shadow: $shadow;
			border-radius: 15px;
			z-index: 1;
			padding-left: none !important;
			padding: 0 0 0 0 !important;

			.item {
				border-radius: 15px;
			}
		}
		.owl-carousel {
			.owl-dots,
			.owl-caption-holder {
				display: none;
			}
			.owl-next,
			.owl-prev {
				background: white;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 48px;
				aspect-ratio: 1/1;
				margin: 0;
				transform: translate(50%, -50%);
				i {
					font-size: 30px !important;

					&::before {
						content: "\f0a9";
						font-weight: 400 !important;
						color: $green;
					}
				}
				&:hover {
					width: 56px;
				}
			}
			.owl-prev {
				transform: translate(-50%, -50%) scaleX(-1);
			}
		}
	}
	.container.second {
		padding-bottom: 6vh;
		.text {
			transform: translate(0, 6vh);
			padding: 3vh 3vw 3vh 10vw !important;
		}
		.image-slide {
			transform: translate(5vw, 12vh);
		}
	}
	.buttons {
		padding: 6vh 0 0 0;
		ul {
			display: flex;
			justify-content: center;
			gap: 1rem;
			list-style: none;
		}
	}
	@include media-breakpoint-down(md) {
		.container.first,
		.container.second {
			.container-holder {
				max-width: 100%;
				flex-flow: column;
				justify-content: center;
				align-items: center;
				margin: auto;
				.text,
				.image-slide {
					flex-basis: 90%;
					max-width: 90%;
					transform: none;
				}
				.text {
					z-index: 2;
					padding: 2.5rem 1.5rem 3.5rem 1.5rem !important;
				}
				.image-slide {
					margin-top: -3vh;
					z-index: 1;
					box-shadow: none !important;
				}
			}
		}
		.container.second {
			.container-holder {
				flex-flow: column-reverse;
			}
		}
		.owl-nav {
		}
		.buttons {
			padding: 1vh 1rem !important;
			margin: auto;
			ul {
				flex-flow: column;
				padding: 0;
				li {
					display: flex;
					justify-content: center;
				}
			}
		}
	}
}
// facilities-section
.facilities-section {
	margin: 6vh 0;
	padding: 0 0 6vh 0;
	.intro {
		margin: 9vh auto 6vh auto;
		text-align: center;
		.container-holder .wysiwyg {
			max-width: 750px;
			margin: auto;
		}
	}
	@include media-breakpoint-down(md) {
		.title {
			font-size: 36px;
		}
	}
}

// faq section
.faq-section {
	padding: 6vh 0;
	.intro {
		text-align: center;
	}
	.faq-items {
		.faq-item {
			background: transparent;
			border-bottom: 1px solid $green !important;
			.faq-question {
				background: transparant !important;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border: none !important;
				padding: 10px 0 !important;

				.faq-category {
					margin: 0;
					margin-right: auto;
					* {
						font-size: $h5-font-size !important;
					}
				}
				.faq-icon {
					all: unset !important;
					left: auto;
					right: 0;
					i {
						color: $primary;
						font-size: 50px;
						&::before {
							content: "\2b";
						}
					}
				}
			}
			&.active {
				.faq-icon i {
					font-size: 30px !important;
					&::before {
						content: "\f068";
					}
				}
			}
			.faq-answer {
				padding: 0 !important;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}

// mini-sab
.mini-sab {
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 20px 0 15px 7px;
			border-radius: 150px;
			background: $green;
			color: #fff;

			// below eyecatcher
			// @include media-breakpoint-up(lg) {
			// 	margin-top: -50px;
			// 	padding-left: 30px;
			// 	padding-right: 30px;
			// }

			// above eyecatcher
			@include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 1rem;
			}
			&::after {
				content: url("/images/Path 38.svg");
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, 75%);

			}
		}
	}
	@include media-breakpoint-down(md) {
		background: $blue;
		position: relative;
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			height: 9vh;
			width: 100%;
			background: url("/images/blue-wave.svg");
			background-size: contain;
			background-repeat: repeat-x;
			background-position: center;
			transform: translateY(50%);
		}
		.container-holder {
			transform: translateY(-30%);
			max-width: 85%;
			border-radius: 26px;
		}
	}
}

// header
.header {
	z-index: 998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: linear-gradient(to bottom, rgba(black, 0.3), rgba(black, 0));
	transition: 0.5s;

	&.sticky {
		background: $white;
		box-shadow: $shadow;
		.column {
			height: 9vh !important;
			max-height: 9vh !important;
			transition: 0.35s ease;
			.nav-item.active,
			.nav-item:hover {
				.nav-link {
					color: $primary !important;
				}
			}
			.nav-link {
				color: $green-dark !important;
			}
		}
		.dropdown-menu.show {
			background: $white !important;
		}
		.logo {
			height: 10vh;
			transition: 0.75s ease;
			transition-delay: 0.5s;
		}
	}
	&#sticky {
		@include media-breakpoint-down(md) {
			background: $white;
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				height: 4rem;
				width: 100%;
				pointer-events: none;
				background-color: $primary;
				background: url("/images/Repeat Grid 9.svg");
				background-size: contain;
				background-repeat: repeat-x;
				background-position: center;
				z-index: -1;
				transform: translateY(50%);
			}
		}
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;
				padding-left: 0;
				// height: 12vh;
				max-height: 12vh;
				transition: 0.35s ease;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					align-items: flex-start;
					justify-content: flex-end;
					padding-bottom: 0.75rem;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// logo
	.logo {
		order: -1;
		width: 275px;
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $green-dark;
		border-radius: 0px 0px 15px 0px;
		margin: 0;
		align-self: flex-start;
		transition: 0.75s ease;
		transition-delay: 0.5s;
		a {
			@extend .d-block;

			img {
				width: 146px;
				height: 60px;
			}
		}
		@include media-breakpoint-down(md) {
			width: 171px;
			height: 80px;
			padding: 1.5rem;
			margin-right: auto;
			float: left;
			position: absolute;
			top: 0;
			left: 0;
			img {
				width: 116px;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			min-height: 100vh;
			position: fixed;
			right: -100vw;
			top: 0;
			bottom: 0;
			width: 100vw;
			order: 4;
			background: $white;
			transition: 0.5s ease-out;
			z-index: -1;
			box-shadow: $shadow;
			display: flex;
			align-items: center;
			justify-content: center;
			&.show {
				right: 0;
				transition: 0.5s ease-in;
			}
			.nav-link {
				color: $primary;
				font-weight: 600;
				font-size: $h6-font-size;
			}

			.dropdown-menu.show {
				position: static !important;
				transform: none !important;
				border: 0 !important;
				box-shadow: none !important;
				.nav-link {
					font-weight: 300 !important;
				}
			}
		}

		@include media-breakpoint-up(xl) {
			margin: auto;
			max-width: fit-content;
			.navbar-nav {
				gap: 1.5rem;
				.nav-item {
					.nav-link {
						color: $white;
						font-weight: 700;
					}
					&.active,
					&:hover {
						.nav-link {
							color: $green-dark;
							font-weight: 900;
						}
					}
				}
			}
			.dropdown-menu.show {
				border: none;
				background: none;
				box-shadow: none;
				padding-left: 0.5rem;
				border-radius: 15px;
				&::before,
				&::after {
					display: none;
				}
				.nav-link {
					font-weight: 200 !important;
				}
				.nav-link:hover {
					font-weight: 700 !important;
				}
			}
		}
	}
	.header-btn {
		a {
			@extend .btn, .btn-primary;
			margin-right: 1rem;
			&::after {
				display: none;
			}
		}
		@include media-breakpoint-down(md) {
			a {
				margin-right: 0;
				font-size: 13px !important;
				padding: 4px 12px !important;
			}
		}
		@include media-breakpoint-down(xs) {
			a {
				font-size: 11px !important;
				// padding: 4px 8px !important;
			}
		}
	}
	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(md) {
			order: 1;
			display: flex;
			background: $green;
			border-radius: 38px;
			float: right;
			z-index: 2;
margin-left: 0.1rem;
			i {
				display: flex;
				align-items: center;
				flex-flow: row-reverse;
				gap: 5px;
				color: $white;

				&::before {
					font-size: 11px !important;
				}

				&::after {
					content: "MENU";
					color: $white;
					font-weight: 600;
					font-family: $font-family-primary;
					font-size: 11px;
				}
			}
		}
	}
	// site-switcher
	.site-switcher {
		@include media-breakpoint-down(md) {
			order: -1;
			margin-right: 0;
			margin-left: auto;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			padding-top: 5px;
			float: right;
			margin-bottom: 5px;
		}
	}
}

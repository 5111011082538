// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-dots,
	.owl-nav,
	.owl-btn,
	.owl-subtitle,
	.owl-description {
		display: none !important;
	}

	.owl-title {
		font-family: $font-family-secondary !important;
		font-size: 60px;
	}
}

section.eyecatcher {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		height: 9vh;
		width: 100%;
		background: url("/images/blue-wave.svg");
		background-size: contain;
		background-repeat: repeat-x;
		background-position: center;
		transform: translateY(50%);
		z-index: 1;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 50vh;
	min-height: 540px;
	max-height: 1080px;
	position: relative;
	@include media-breakpoint-down(md) {
		.owl-title,
		.owl-caption-holder {
			font-size: 36px;
			height: 100%;
			display: flex;
			align-items: flex-end;
			padding-bottom: 6vh;
		}
	}

	@include media-breakpoint-up(sm) {
		height: 80vh;
	}
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		height: 9vh;
		width: 100%;
		background: url("/images/blue-wave.svg");
		background-size: contain;
		background-repeat: repeat-x;
		background-position: center;
		transform: translateY(50%);
	}
}
.eyecatcher.large.ec-content {
	margin: 6vh 0;
}
.eyecatcher.large.ec-content,
.eyecatcher.large.ec-content .owl-carousel .item {
	height: 85vh;
	min-height: 540px;
	max-height: 1080px;
	position: relative;
	.owl-caption-holder {
		height: calc(100% - 15vh);
		display: flex;
		align-items: flex-end;
		.owl-title {
			font-size: 48px;
			@include media-breakpoint-down(md) {
				font-size: 32px;
			}
		}
	}
	.owl-nav {
		display: block !important;
		.owl-prev,
		.owl-next {
			top: calc(100% - 15vh);
			display: flex;
			justify-content: center;
			align-items: center;
			width: 48px;
			aspect-ratio: 1/1;
			margin: 2rem;
			background: rgba($white, 0.05);
			border-radius: 50%;
			i {
				font-size: 30px !important;

				&::before {
					content: "\f0a9";
					font-weight: 400 !important;
					color: $green;
				}
			}

			&:hover {
				background: rgba($white, 0.3);
			}
			@include media-breakpoint-down(md) {
				margin: 2rem 0.5rem;
			}
		}
		.owl-prev i {
			transform: scaleX(-1);
		}
	}
	@include media-breakpoint-up(sm) {
		height: 80vh;
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 7vh;
		width: 100%;
		background: url("/images/Repeat Grid 17.svg");
		background-size: cover;
		background-repeat: repeat-x;
		background-position: center;
		transform: translateY(-50%);
	}

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		height: 7vh;

		background: url("/images/Repeat Grid 16-small.svg");

		background-repeat: repeat-x;
		background-position: center left;

		transform: translate(0, 50%);
	}
}

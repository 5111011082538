// footer
.footer {
	background: $green-dark;
	margin: 9vh auto auto auto;
	padding: 6vh 0 1rem 0;
	position: relative;
	.intro {
		text-align: center;
		margin: 6vh 0;
		* {
			color: $white;
			font-size: 15px;
		}
		.logo img {
			max-width: 210px;
			width: 210px;
		}

		.footer-text {
			margin: 1.5vh;
		}
	}
	.footer-socket {
		text-align: center;
		a {
			color: $white;
			font-size: 13px;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		ul.list {
			gap: 1rem;
			justify-content: center;
		}
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 9vh;
		width: 100%;
		background: url("/images/blue-wave.svg");
		background-size: contain;
		background-repeat: repeat-x;
		transform: translateY(-50%);
		background-position: center;
	}
}

// btn
.btn {
	font-weight: 700;
	border-radius: 38px;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	display: flex;
	max-width: max-content;
	align-items: center;

	&::after {
		content: "\f061";
		font-family: $font-awesome;
		margin-left: 0.5rem;
	}

	&:hover {
		&::after {
			transform: rotate(-30deg);
		}
	}
	// btn-primary
	&.btn-primary {
		&:hover {
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		border-color: $primary;
		color: $primary;
		border-width: 2px;
		&:hover {
			color: $white;
			&::after {
				transform: rotate(-30deg);
			}
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-outline-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
